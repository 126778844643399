<template>
  <div class="caseDetail">
    <PageTitle ttl-en="CASE" ttl-jp="_想定課題" />
    <div class="inner">
      <p class="caseDetail__mv js-scroll">
        <img src="/genbashiko/img/case/case07/mv.jpg" alt="" />
      </p>
      <section class="caseDetail__outline">
        <h1 class="js-scroll">
          塗装検査ブースの環境を改善するために
          <br />手間・時間・コストがかかる
        </h1>
        <p class="caseDetail__outline__txt js-scroll">
          塗装検査ではゼブラ照明を用いて、光が当たっている部分とそうでない部分の境界線上で、キズや打痕などを発見するという手法が採られています。ところが、塗装やボデーの検査ブースへの導入は、照明設置のレイアウトを考えたり、大きな改修計画など何かと事前準備が必要になります。
        </p>
        <div class="caseDetail__outline__cate js-scroll">
          <p>担当領域:</p>
          <ul>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '新たに検査現場を構築したい' }}"
              >
                新たに検査現場を構築したい
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '既存の検査場を改修したい' }}"
              >
                既存の検査場を改修したい
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '現状の課題を確認したい' }}"
              >
                現状の課題を確認したい
              </router-link>
            </li>
            <li>
              <router-link class="category" :to="{ name: 'Case', query: { category: '塗装完成車工程' }}">
                塗装完成車工程
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: 'ボデー・プレス工程' }}"
              >
                ボデー・プレス工程
              </router-link>
            </li>
          </ul>
        </div>
      </section>
      <section class="caseDetail__env">
        <h3 class="caseDetail__env__ttl js-scroll">
          想定される環境
        </h3>
        <ul class="js-scroll">
          <li>完成車検査</li>
          <li>ボデー工程検査</li>
        </ul>
      </section>
      <section class="caseDetail__ex">
        <h3 class="caseDetail__ex__ttl js-scroll">
          想定課題と具体的な改善施策例
        </h3>
        <div class="caseDetail__ex__problem js-scroll">
          <div class="caseDetail__ex__problem__p">
            <p class="ttl">
              課題
            </p>
            <p class="txt">
              ゼブラ照明を導入する予算が足りない
            </p>
          </div>
          <div class="caseDetail__ex__problem__a">
            <span class="ttl">施策</span>
            <p class="txt">
              可動型ゼブラ照明『ゼブラスタンド』を導入する
            </p>
          </div>
          <div class="caseDetail__ex__problem__img">
            <p>
              <img src="/genbashiko/img/case/case05/image01.jpg" alt="" />
            </p>
            <p>
              <img src="/genbashiko/img/case/case05/image02.jpg" alt="" />
            </p>
          </div>
          <p class="caseDetail__ex__problem__cap">
            セブラスタンドは、さらに効率的にゼブラ検査を行える、便利なキットです。検査対象への照射角を変えることによって、従来は見ることができなかった凹凸やキズを発見できるようになります。また、他の現場への移動に便利なキャスター付きなので、他の検査現場と兼用することを可能にし、低予算で導入することができます。
          </p>
        </div>
        <div class="caseDetail__ex__movie js-scroll">
          <div class="caseDetail__ex__movie__block">
            <p class="caseDetail__ex__movie__ttl">
              ▼【日本語版】商品ムービー
            </p>
            <div class="caseDetail__ex__movie__iframeWrap">
              <iframe
                src="https://www.youtube.com/embed/Ifl1AAtcZug"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div class="caseDetail__ex__movie__block">
            <p class="caseDetail__ex__movie__ttl">
              ▼【海外版】商品ムービー
            </p>
            <div class="caseDetail__ex__movie__iframeWrap">
              <iframe
                src="https://www.youtube.com/embed/t_jt-j0T60U"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
        <p class="caseDetail__ex__download js-scroll">
          <a
            href="/genbashiko/upload/download_pdf/PA-LZS.pdf"
            target="_blank"
            >課題に関する資料ダウンロード</a
          >
        </p>
      </section>
    </div>
  </div>
</template>
<script>
import PageTitle from '@/components/PageTitle.vue'

export default {
  components: {
    PageTitle
  },
  head: {
    title: {
      inner: 'Case07'
    },
    meta: [
      { property: 'og:title', content: 'Case07｜GENBA SHIKO' },
      {
        name: 'description',
        content:
          '1つの塗装検査ラインで様々な塗色のワークに合った照明の明るさや光源色で検査ができない環境には、自動調色調光システムを導入して労働環境とタクトタイムの改善をサポートします。'
      },
      {
        property: 'og:description',
        content:
          '1つの塗装検査ラインで様々な塗色のワークに合った照明の明るさや光源色で検査ができない環境には、自動調色調光システムを導入して労働環境とタクトタイムの改善をサポートします。'
      },
      {
        property: 'og:url',
        content: 'https://luci.co.jp/genbashiko/case/case07'
      },
      { property: 'og:type', content: 'article' }
    ]
  }
}
</script>
